<template>
  <container>
    <header>
      <h1>Adhere.ly</h1>
    </header>
    <v-progress-circular
      color="deep-orange-lighten-2"
      indeterminate
    />
  </container>
</template>

<script>
export default {
  name: 'SSO',
  mounted() {
    console.log('route', this.$router.to)
  }
}
</script>